@import url("https://fonts.googleapis.com/css2?family=Bellefair&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");

/*Overall Styles*/

* {
	margin: 0;
	padding: 0;
	color: azure;
	overflow: hidden;
}

body {
	max-height: 100vh;
	margin: 0;
	padding: 0;
}

/*Backgrounds */

.header-img {
	margin: 0;
	padding: 0;
	background-image: url(./components/assets/home/background-home-desktop.jpg);
	background-size: cover;
	min-height: 100vh;
}

.destination-bg {
	background-image: url(./components/assets/destination/background-destination-desktop.jpg);
	background-size: cover;
}

/*Navbar*/
.logo {
	width: 4em;
	height: 4em;
	overflow: hidden;
}

.nav-links,
.nav-list {
	display: flex;
	justify-content: space-between;
}

.nav-links {
	margin: 2em 0 3em 5em;
	height: 5em;
	align-items: center;
}

.burger-menu {
	display: none;
}

.nav-list {
	flex-direction: row;
	list-style: none;
	padding: 2em 5em 2em 5em;
	background-color: #f2f2f25b;
	/* height: 1.1em; */
	width: 60%;
}

.nav-a {
	justify-content: flex-end;
	transition: 0.5s ease;
	transition-delay: 0s;
	font-size: 1.5vw;
	font-weight: 100;
}

.nav-a:hover {
	border-bottom: rgba(245, 245, 245, 0.767) solid;
}

.nav-a:active {
	border-bottom: rgb(255, 255, 255) solid;
}

.nav-liner {
	width: 13em;
	margin-right: -4em;
}

/*Page Content*/

/*Home Page*/

.space-main {
	display: flex;
	align-items: flex-end;
	justify-content: space-evenly;
	gap: 150px;
	margin: 15em 0 4em 0;
}

.space-header,
.planet-heading {
	font-family: "Bellefair";
	font-weight: 400;
	font-size: 9em;
	line-height: 1.4em;
}

.space-subheading {
	font-family: "Barlow-Condensed";
	font-weight: 100;
}

.space-paragraph {
	font-family: "Barlow";
}
.space-section {
	width: 27.8em;
}

/*Button*/

.explore-btn {
	background-color: #f2f2f2;
	width: 15em;
	height: 15em;
	border-radius: 100%;
	font-family: "Bellefair";
	margin-bottom: 20px;
}

.explore-btn p {
	font-size: 34px;
	color: black;
}

/* Loading */

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

/* Destination */
.destination-sect {
	margin: 0.4em 1.5em 5em 0em;
}

.desti-content {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.planet-pic {
	width: 22em;
	animation: rotation 20s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-359deg);
	}
}

.planet-heading {
	font-size: 4.5em;
}

.all {
	width: 295px;
}

.description {
	font-size: 1.2em;
	text-decoration: none;
	margin: 0.3em 0 1.5em 0;
}

.all-destination {
	margin-top: 3em;
}

.desti-pick,
.crew-pick,
.tech-pick {
	font-size: 1.3em;
	font-weight: 100;
	margin-left: 3em;
	width: 100%;
}

.desti-buttons {
	display: flex;
	flex-direction: row;
	margin-bottom: 0.6em;
	gap: 0.8em;
	width: 330px;
}

.info-sect {
	margin-top: 1em;
	display: flex;
	flex-direction: row;
	gap: 3.5em;
}

.info-sect p {
	font-size: 0.6em;
}

.info-sect h2 {
	font-size: 1.3em;
	font-weight: 100;
}

.desti-btn {
	background-color: transparent;
	font-size: 0.8em;
	color: rgba(245, 245, 245, 0.671);
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom-color: transparent;
}

.desti-btn:hover {
	border-bottom-color: rgba(255, 255, 255, 0.74);
}

.desti-buttons .carousel__dot--selected {
	border-bottom-color: rgb(255, 255, 255);
}

/*Crew */
.crew-bg {
	background-image: url(./components/assets/crew/background-crew-desktop.jpg);
}

.crew-content {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-evenly;
}

.crew-pic {
	width: 27em;
	height: 40em;
}

.words {
	margin: 9.5em 0 5.5em 0;
	width: 375px;
}

.role,
.description {
	font-weight: 100;
}

.role,
.crew-heading,
.term {
	margin-bottom: 0.3em;
}

.crew-heading {
	font-size: 2.3em;
}

.role,
.heading-number,
.term {
	color: gray;
}

.crew-buttons {
	display: flex;
	flex-direction: row;
	gap: 3em;
}

.crew-btn {
	width: 0.9em;
	height: 0.9em;
	border-radius: 100%;
	border: none;
	background-color: rgba(128, 128, 128, 0.877);
}

.crew-btn:hover {
	background-color: rgba(255, 255, 255, 0.733);
}

.crew-buttons .carousel__dot--selected {
	background: white;
}

.tech-content {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	margin-top: 3em;
}

.tech-bg {
	background: url(./components/assets/technology/background-technology-desktop.jpg);
}

.tech-buttons {
	display: flex;
	flex-direction: column;
	gap: 2em;
	margin-left: 4em;
	margin-top: 6em;
}

.tech-btn {
	width: 3em;
	height: 3em;
	border-radius: 100%;
	border-width: 0.1em;
	border-color: white;
	font-size: 1.6em;
	background-color: transparent;
}

.tech-btn:hover {
	background-color: rgba(255, 255, 255, 0.705);
	color: black;
}

.tech-buttons .carousel__dot--selected {
	background-color: rgb(255, 255, 255);
	color: black;
}

.term {
	font-size: 0.6em;
}

.tech-heading {
	margin-bottom: 0.34em;
}

.tech-pic {
	height: 29em;
	width: 25em;
}

.mobile-tech {
	display: flex;
	flex-direction: row-reverse;
	margin-left: 4em;
	gap: 10em;
}
/* Media size 1024px to 1270px*/

@media screen and (min-width: 1024px) and (max-width: 1270px) {
	.space-main {
		margin-top: 10em;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		gap: 250px;
	}

	.space-header {
		font-size: 5em;
		line-height: 1.1em;
	}

	.space-subheading {
		font-size: 0.7em;
	}

	.space-paragraph {
		font-size: 0.8em;
	}
	.space-section {
		width: 20em;
		margin-top: 1.5em;
	}

	/* Destination Section */

	.desti-content img {
		width: 19em;
		height: 19em;
	}

	.words {
		margin: 6em 0 3em 0;
	}

	.planet-heading {
		font-size: 3em;
	}

	/* Crew Section */

	.crew-pic {
		height: 27.5em;
		width: 20em;
	}

	/* Tech Section */
	.tech-bg {
		background-image: url(./components/assets/technology/background-technology-desktop.jpg);
		background-size: cover;
	}
	.tech-buttons {
		margin-top: 3em;
	}

	.tech-btn {
		width: 2em;
		height: 2em;
	}

	.words-tech {
		margin-top: 2.5em;
		margin-left: -6em;
		margin-right: 1.7em;
	}

	.tech-pic {
		height: 20em;
	}
}
/* Media size 750px to 1000px */

@media screen and (min-width: 750px) and (max-width: 1020px) {
	/* Nav Bar Tablet version */
	* {
		overflow-y: auto;
		overflow-x: hidden;
	}
	.logo {
		width: 3em;
		height: 3em;
	}

	.nav-links {
		margin-top: 0;
		overflow-y: hidden;
	}

	.nav-list {
		height: 1.1em;
		width: 20em;
		overflow-y: hidden;
	}

	.nav-p {
		font-size: 14px;
		margin-right: 8px;
	}

	.nav-liner {
		display: none;
	}

	.nav-a {
		overflow-y: hidden;
	}
	/* Home Page Tablet Version */

	.space-main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}

	.header-img {
		background-image: url(./components/assets/home/background-home-tablet.jpg);
		background-size: cover;
		min-height: 100vh;
	}

	.space-header {
		font-size: 10em;
		line-height: 1.1em;
		overflow-y: hidden;
	}

	.space-paragraph {
		font-size: 1.3em;
	}
	.space-section {
		width: 31em;
	}

	/* Destination/Crew Tablet Version */

	.desti-content,
	.crew-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		gap: 5em;
	}

	.destination-sect {
		margin: 0;
	}

	.all {
		width: 520px;
	}

	.desti-buttons {
		justify-content: center;
		width: 520px;
	}

	.info-sect {
		justify-content: center;
	}

	/* Crew Section */
	.crew-bg {
		background-image: url(./components/assets/crew/background-crew-tablet.jpg);
		background-size: cover;
	}

	.crew-content {
		flex-direction: column-reverse;
	}

	.crew-pic {
		width: 30em;
		height: 30em;
	}

	.words {
		margin: 3.5em 0 3.5em 0;
		width: 540px;
	}

	.crew-buttons {
		justify-content: center;
	}

	.tech-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.tech-bg {
		background: url(./components/assets/technology/background-technology-tablet.jpg);
		background-size: cover;
	}

	.tech-buttons {
		flex-direction: row;
		margin-left: -2em;
	}

	.tech-pic {
		height: 19em;
		width: 100vw;
	}

	.mobile-tech {
		display: flex;
		flex-direction: column-reverse;
		margin: 0;
		gap: 10px;
		text-align: center;
		justify-content: center;
		align-items: center;
	}
}

/* Media size 250px to 750px*/
@media screen and (min-width: 200px) and (max-width: 750px) {
	* {
		overflow-y: auto;
		overflow-x: hidden;
	}

	/* sidebar Nav */
	.navvie {
		margin: 0;
		display: flex;
		flex-direction: row;
	}

	.burger-menu {
		display: block;
		position: relative;
	}

	.burger-menu button {
		position: fixed;
		right: 40px;
		top: 40px;
		z-index: 10;
		cursor: pointer;
		background-color: transparent;
		border: none;
	}

	.menuNav {
		overflow-y: scroll;
		list-style: none;
		position: fixed;
		top: 0;
		background: black;
		right: 0;
		bottom: 0;
		height: 100vh;
		width: 0;
		overflow: hidden;
		max-width: 290px;
		z-index: 9;
	}

	.menuNav.showMenu {
		width: 100%;
	}

	.nav-a {
		display: block;
		padding: 10px 40px;
		text-decoration: none;
		color: #3fffd2;
		text-transform: uppercase;
		font-weight: bold;
	}

	.menuNav li:first-child {
		margin-top: 7rem;
	}

	/* .burgerbutton{
	background-color: transparent;
} */

	/* Nav Bar Mobile version */

	.logo {
		width: 2em;
		height: 2em;
	}

	.nav-links {
		margin: 0 0 0 40px;
		padding: 10px 0 0 0;
	}

	.nav-list {
		display: none;
		overflow-y: hidden;
	}

	.nav-p {
		font-size: 14px;
		margin-right: 8px;
	}

	.nav-liner {
		display: none;
	}

	/* Home Page Tablet Version */

	.space-main {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		gap: 130px;
	}

	.header-img {
		background-image: url(./components/assets/home/background-home-mobile.jpg);
		background-size: cover;
		min-height: 100vh;
	}

	.space-header {
		overflow-y: hidden;
		font-size: 5em;
		line-height: 1.1em;
	}

	.space-subheading {
		font-size: 0.7em;
	}

	.space-paragraph {
		font-size: 0.8em;
	}
	.space-section {
		width: 20em;
		margin-top: 1.5em;
	}

	/* Destination/Crew Tablet Version */

	.desti-content,
	.crew-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		gap: 1.5em;
		margin: 0;
	}

	.desti-pick,
	.crew-pick,
	.tech-pick {
		margin: 0 0 0 2em;
		font-size: 1em;
	}

	.planet-heading {
		font-size: 2em;
	}

	.all p {
		font-size: 0.9em;
	}

	.all {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 3em;
	}

	.all hr {
		width: 20em;
	}

	.desti-buttons {
		justify-content: center;
		width: 370px;
		font-size: 0.8em;
		gap: 2em;
	}

	.description {
		font-size: 0.9;
	}

	.info-sect {
		flex-direction: row;
		justify-content: center;
	}

	.info-box p,
	.info-box2 p {
		font-size: 0.7em;
	}

	.info-box h2,
	.info-box2 h2 {
		font-size: 1.3em;
	}

	.planet-pic {
		width: 10em;
		height: 10em;
	}

	/* Crew Section */
	.crew-bg {
		background-image: url(./components/assets/crew/background-crew-mobile.jpg);
		background-size: cover;
		height: 100vh;
	}

	.crew-content {
		flex-direction: column;
	}

	.crew-pic {
		width: 12em;
		height: 13.4em;
		margin-top: 2em;
		padding: 0 50px;
		border-bottom: white solid 1px;
	}

	.all-crew {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}

	.words {
		margin: 0.5em 0 0.5em 0;
		width: 300px;
	}

	.role {
		margin-top: 2em;
		font-size: 0.9em;
	}

	.crew-heading {
		font-size: 1.6em;
	}

	.crew-words {
		font-size: 1em;
	}
	.crew .crew-buttons {
		margin-top: 1em;
		justify-content: center;
	}

	/* Tech */

	.tech-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.tech-bg {
		background: url(./components/assets/technology/background-technology-mobile.jpg);
		background-size: cover;
		height: 100vh;
	}

	.tech-buttons {
		margin: 3em 0 0 0;
		flex-direction: row;
		justify-content: center;
	}

	.tech-btn {
		width: 2em;
		height: 2em;
	}

	.tech-heading {
		font-size: 1.6em;
	}

	.description-tech {
		font-size: 0.9em;
	}

	.tech-pic {
		height: 9em;
		width: 100vw;
	}

	.mobile-tech {
		display: flex;
		flex-direction: column-reverse;
		margin: 0;
		gap: 10px;
		text-align: center;
		justify-content: center;
		align-items: center;
	}
}
